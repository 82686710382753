<template>
  <v-container>
    <h4> {{ $t('organisations.invitationsTitle') }}</h4>
    <div v-if="organisationInvitations.length === 0" class="text-center">
      <span class="text--primary caption">
        {{ $t("organisations.invitationsEmpty") }}
      </span>
    </div>

    <v-card
      v-for="invitation in organisationInvitations"
      :key="invitation.id"
      class="mx-auto my-2"
      max-width="400"
    >
      <v-img
        v-if="invitation.organisation.picture_url"
        class="white--text align-end"
        height="200px"
        :src="invitation.organisation.picture_url"
      >
      </v-img>
      <v-card-title>{{ invitation.organisation.name }}</v-card-title>

      <v-card-subtitle class="pb-0 my-1 text-center">
        <span class="text--primary caption mx-2">
          {{ $t("organisations.invitedBy") }}
        </span>

        <userAvatar
          :size="16"
          :show_name_in_tooltip="false"
          :first_name="invitation.inviting_user.first_name"
          :last_name="invitation.inviting_user.last_name"
          :avatar_url="invitation.inviting_user.avatar_url"
          :email="invitation.inviting_user.email"
        />
        {{ getUserName(invitation.inviting_user) }}
        <span class="text--primary caption">
          {{ $t("organisations.invitationSentence2") }}
        </span>
      </v-card-subtitle>

      <v-card-text class="caption text-center">
        <span>{{ $t("organisations.invitationSentence3") }}</span>
      </v-card-text>

      <v-card-actions>
        <v-btn color="success" text @click="accept(invitation.id)">
          {{ $t("organisations.invitationAccept") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="decline(invitation.id)">
          {{ $t("organisations.invitationDecline") }}
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import userAvatar from "@/components/shared/userAvatar";
import ACCEPT_INVITATION from "@/graphql/organisation_invitations/accept.gql";
import DECLINE_INVITATION from "@/graphql/organisation_invitations/decline.gql";

export default {
  name: "invitations",
  components: { userAvatar },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["organisationInvitations"]),
  },
  methods: {
    ...mapActions(["getOrganisations"]),
    getUserName(user) {
      let name = `${user.first_name ? user.first_name : ""} ${
        user.last_name ? user.last_name : ""
      }`;
      return name != " " ? name : user.email;
    },
    accept(id) {
      this.$apollo.mutate({
        mutation: ACCEPT_INVITATION,
        variables: { id: id }
      }).then(() => {
        this.sendSuccess(this.$t('organisations.invitationAccepted'))
        this.getOrganisations();
        this.$emit('invitationAccepted');
      })
      .catch((error) => {
        this.sendError(error);
        console.error(error);
      });
    },
    decline(id) {
      this.$apollo.mutate({
        mutation: DECLINE_INVITATION,
        variables: { id: id }
      }).then(() => {
        this.sendSuccess(this.$t('organisations.invitationDeclined'))
        this.getOrganisations();
      })
      .catch((error) => {
        this.sendError(error);
        console.error(error);
      });
    },
  },
};
</script>
