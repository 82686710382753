<template>
  <public-layout displayTermsOfUse>
    <template v-slot:form>
      <!--  <div v-if="state == State.CREATE_ORGA_1">
        <h2 class="title_dark_blue--text d-md-none h2padd">
          {{ $t("organisations.headerLineCreate") }} <br />
        </h2>
        <h1 class="title_dark_blue--text d-none d-md-block">
          {{ $t("organisations.headerLineCreate") }} <br />
        </h1>

        <v-text-field
          v-model="organisationName"
          :label="$t('organisations.name')"
          placeholder=""
          filled
          dense
          @keyup.enter="next"
          :rules="[
            (e) => !!organisationName || $t('organisations.namePresence'),
          ]"
        />

        <v-btn
          block
          color="button_blue"
          elevation="0"
          large
          class="mt-5 white--text"
          @click="next"
          :disabled="!organisationName"
        >
          <b>{{ $t("misc.next") }}</b>
          <v-icon class="pl-1" small>mdi-arrow-right-thick</v-icon>
        </v-btn>
        <a @click="setState(State.JOIN_ORGA)" v-if="shouldJoinOrga">{{
          $t("organisations.headerLineJoin")
        }}</a>
      </div> -->

      <!-- <div v-if="state == State.CREATE_ORGA_2"> -->
      <!-- Header for small screens -->
      <!-- <h2 class="title_dark_blue--text d-md-none h2padd">
          {{ $t("organisations.headerLineInvite") }} <br />
        </h2> -->
      <!-- Header for large screens -->
      <!-- <h1 class="title_dark_blue--text d-none d-md-block">
          {{ $t("organisations.headerLineInvite") }} <br />
        </h1>
        <v-text-field
          v-model="inviteEmails"
          :label="$t('organisations.enterEmailInvitation')"
          :placeholder="placeholderInvitation"
          filled
          dense
          @keyup.enter="createOrganisation"
          :rules="[(e) => validEmails || $t('organisations.invalidEmailsMsg')]"
        />
        <v-btn
          block
          color="button_blue"
          elevation="0"
          large
          class="mt-5 white--text"
          @click="next"
          :disabled="!validEmails"
        >
          <b>{{ $t("misc.confirm") }}</b>
          <v-icon class="pl-1" small>mdi-arrow-right-thick</v-icon>
        </v-btn> -->
      <!-- <a @click="setState(State.CREATE_ORGA_1)"> {{ $t("misc.back") }}</a> -->
      <!-- </div> -->

      <div v-if="state == State.FINAL_STATE">
        <v-btn
          block
          color="button_blue"
          elevation="0"
          large
          class="mt-5 white--text"
          to="/"
        >
          <b>{{ $t("organisations.goToSite") }}</b>
          <v-icon class="pl-1" small>mdi-arrow-right-thick</v-icon>
        </v-btn>

        <v-btn
          block
          color="button_blue"
          elevation="0"
          large
          class="mt-5 white--text"
          :href="addInUrl"
          target="_blank"
        >
          <b>{{ $t("organisations.installAddOn") }}</b>
          <v-icon class="pl-1" small>mdi-arrow-right-thick</v-icon>
        </v-btn>
      </div>

      <div v-if="state == State.LOADING">
        {{ $t("misc.loading") }}
      </div>

      <div v-if="state == State.JOIN_ORGA">
        <!-- Header for small screens -->
        <h2 class="title_dark_blue--text d-md-none h2padd">
          {{ $t("organisations.headerLineJoin") }} <br />
        </h2>
        <!-- Header for large screens -->
        <h1 class="title_dark_blue--text d-none d-md-block">
          {{ $t("organisations.headerLineJoin") }} <br />
        </h1>

        <invitations
          v-on:invitationAccepted="state = State.FINAL_STATE"
          v-if="!shouldContactSales"
        />
        <div v-if="shouldContactSales" class="pa-1">
          <br />

          <div>
            <span class="caption">
              {{ $t("misc.thanksForSignin") }}
            </span>
          </div>
          <br />
          <span class="text--primary caption">
            {{ $t('misc.noOrganisationsSentence') }}
            <v-btn
              block
              color="button_blue"
              elevation="0"
              large
              class="my-4 white--text"
              href="https://getcollective.hubspotpagebuilder.com/contact-our-sales-team"
              target="_blank"
            >
              <b>{{ $t("misc.contactSales") }}</b>
              <v-icon class="pl-1" small>mdi-arrow-right-thick</v-icon>
            </v-btn>
          </span>
          <span class="text--primary caption">
            {{ $t("misc.salesAssist") }}
          </span>
        </div>
        <!-- <a @click="setState(State.CREATE_ORGA_1)"
          >{{ $t("organisations.headerLineCreate") }}
        </a> -->
      </div>
    </template>
  </public-layout>
</template>

<script>
//import CREATE_ORGANISATION from "@/graphql/organisations/create.gql";
import { mapGetters } from "vuex";
import invitations from "./invitations";
import publicLayout from "../public/public_layout";
import links from "@/mixins/links";

const State = Object.freeze({
  //CREATE_ORGA_1: 0,
  //CREATE_ORGA_2: 1,
  FINAL_STATE: 2,
  JOIN_ORGA: 3,
  LOADING: 4,
});

export default {
  name: "set_organisation",
  mixins: [links],
  components: { invitations, publicLayout },
  data() {
    return {
      State,
      state: State.JOIN_ORGA,
      organisationName: "",
      inviteEmails: "",
    };
  },
  computed: {
    ...mapGetters(["organisations", "organisationInvitations"]),
    shouldJoinOrga() {
      return this.organisationInvitations.length > 0;
    },
    shouldContactSales() {
      return (
        this.organisationInvitations.length === 0 &&
        this.organisations.length === 0
      );
    },
    placeholderInvitation() {
      return `email1@${this.organisationName.toLowerCase()}.com, email2@${this.organisationName.toLowerCase()}.com`;
    },
    inviteEmailsArray() {
      return this.inviteEmails
        .split(",")
        .map((e) => e.trim())
        .filter((e) => !!e);
    },
    validEmails() {
      for (let i = 0; i < this.inviteEmailsArray.length; i++)
        if (!this.inviteEmailsArray[i].match(/^(.+)@(.+)$/)) return false;
      return true;
    },
  },
  methods: {
    addPeople() {
      this.inviteEmails.push(this.emailTmp);
      this.emailTmp = "";
    },
    /* createOrganisation() {
      if (!this.validEmails)
        return this.sendError(this.$t("organisations.invalidEmailsMsg"));

      this.state = State.LOADING;

      this.sendMutation("create", "organisation", CREATE_ORGANISATION, {
        name: this.organisationName,
        user_id: this.currentUser.id,
        invited_user_emails: this.inviteEmailsArray,
      })
        .then((data) => {
          this.state = State.FINAL_STATE;
          this.getOrganisations();
        })
        .catch(() => {
          this.state = State.CREATE_ORGA_2;
        });
    }, */
    /* next() {
      if (this.state === State.CREATE_ORGA_1) this.state = State.CREATE_ORGA_2;
      else if (this.state === State.CREATE_ORGA_2) this.createOrganisation();
    }, */
    setState(state) {
      if (this.state != state) this.state = state;
    },
  },
  mounted() {
    //if (this.shouldJoinOrga)
    this.state = this.State.JOIN_ORGA;
  },
  watch: {
    "organisationInvitations.length"() {
      if (this.shouldJoinOrga) this.state = this.State.JOIN_ORGA;
    },
  },
  created() {
    document.title =
      "GetCollective - " + this.$t("organisations.headerLineJoin");
  },
};
</script>

<style scoped>
.h2padd {
  padding-top: 100px;
  padding-bottom: 45px;
}

.h1padd {
  padding-top: 70px;
  padding-bottom: 48px;
}

.organisations {
  max-width: 410px;
  margin: auto;
}
</style>
